import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/git-introduction",
  "date": "2014-07-12",
  "title": "GIT INTRODUCTION",
  "author": "admin",
  "tags": ["development", "git"],
  "featuredImage": "feature.jpg",
  "excerpt": "Git is a distributed source control system, that is lighting fast and different from client-server systems. In this tutorial, we will learn how to get started with Git. By the end of this tutorial, you should be able to create a Git repository, record snapshots of your project for safekeeping, and view your project's history."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Setup`}</h2>
    <p>{`Download git from the following locations:`}</p>
    <ul>
      <li parentName="ul">{`OSX: `}<a parentName="li" {...{
          "href": "http://code.google.com/p/git-osx-installer/downloads/list?can=3"
        }}>{`Download`}</a></li>
      <li parentName="ul">{`Windows: `}<a parentName="li" {...{
          "href": "http://msysgit.github.io"
        }}>{`Download`}</a></li>
      <li parentName="ul">{`Linux: `}<a parentName="li" {...{
          "href": "http://book.git-scm.com/2_installing_git.html"
        }}>{`Download`}</a></li>
    </ul>
    <h2>{`Create a new repository`}</h2>
    <p>{`create a new directory, and perform the following operation to create a new git repository.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`git init
`}</code></pre>
    <p>{`Executing git init creates a `}<inlineCode parentName="p">{`.git`}</inlineCode>{` subdirectory in the project root, which contains all of the necessary metadata for the repo.
Aside from the .git directory, an existing project remains unaltered (unlike SVN, Git doesn't require a .git folder in every subdirectory).`}</p>
    <h2>{`Checkout a repository`}</h2>
    <p>{`create a working copy of a local repository by running the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git clone /path/to/repository
`}</code></pre>
    <p>{`when using a remote server, your command will be`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git clone username@host:/path/to/repository
`}</code></pre>
    <h2>{`Workflow`}</h2>
    <p>{`Your local repository consists of 3 `}<strong parentName="p">{`trees`}</strong>{` maintained by git: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Working Directory`}</strong>{` which holds the actual files`}</li>
      <li parentName="ul"><strong parentName="li">{`Index/Staging`}</strong>{` which acts as a staging area`}</li>
      <li parentName="ul"><strong parentName="li">{`HEAD`}</strong>{` which points to the last commit you've made.`}</li>
    </ul>
    <h2>{`Add & Commit`}</h2>
    <p>{`You can propose changes (add it to the Index) using:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add <filename>
`}</code></pre>
    <p>{`This is the first step in the basic git workflow. To actually commit these changes use`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add *
`}</code></pre>
    <p>{`Now the file is committed to the HEAD, but not in your remote repository yet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git commit -m "Commit message"
`}</code></pre>
    <h2>{`Pushing changes`}</h2>
    <p>{`Your changes are now in the HEAD of your local working copy. To send those changes to your remote repository, execute`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git push origin master
`}</code></pre>
    <p>{`Change master to whatever branch you want to push your changes to.
If you have not cloned an existing repository and want to connect your repository to a remote server, you need to add it with`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git remote add origin <server>
`}</code></pre>
    <p>{`Now you are able to push your changes to the selected remote server`}</p>
    <h2>{`Branching`}</h2>
    <p>{`Branches are used to develop features isolated from each other.
The master branch is the `}<inlineCode parentName="p">{`default`}</inlineCode>{` branch when you create a repository.
Use other branches for development and merge them back to the master branch upon completion.
create a new branch named `}<inlineCode parentName="p">{`feature_x`}</inlineCode>{` and switch to it using`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git checkout -b feature_x
`}</code></pre>
    <p>{`switch back to master`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git checkout master
`}</code></pre>
    <p>{`and delete the branch again`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git branch -d feature_x
`}</code></pre>
    <p>{`a branch is not available to others unless you push the branch to your remote repository`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git push origin <branch>
`}</code></pre>
    <h2>{`Update & merge`}</h2>
    <p>{`to update your local repository to the newest commit, execute `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git pull
`}</code></pre>
    <p>{`in your working directory to fetch and merge remote changes.
to merge another branch into your active branch (e.g. master), use`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git merge <branch>
`}</code></pre>
    <p>{`In both cases git tries to auto-merge changes. Unfortunately, this is not always possible and results in conflicts. You are responsible to merge those conflicts manually by editing the files shown by git. After changing, you need to mark them as merged with`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add <filename>
`}</code></pre>
    <p>{`before merging changes, you can also preview them by using`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git diff <source_branch> <target_branch>
`}</code></pre>
    <h2>{`Tagging`}</h2>
    <p>{`It's recommended to create tags for software releases. this is a known concept, which also exists in SVN. You can create a new tag named 1.0.0 by executing`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git tag 1.0.0 3a5e1d87af
`}</code></pre>
    <p>{`The 3a5e1d87af stands for the first 10 characters of the commit id you want to reference with your tag. You can get the commit id by looking at the log. `}</p>
    <h2>{`Logging`}</h2>
    <p>{`You can study repository history using:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git log
`}</code></pre>
    <p>{`You can add a lot of parameters to make the log look like what you want. To see only the commits of a certain author:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git log --author=sean
`}</code></pre>
    <p>{`To see a very compressed log where each commit is one line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git log --pretty=oneline
`}</code></pre>
    <p>{`Or mabe you want to see an ASCII art tree of all the branches, decorated with the names of tags and branches:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git log --graph --oneline --decorate --all
`}</code></pre>
    <p>{`See only which files have changed: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git log --name-status
`}</code></pre>
    <p>{`These are just a few of the possible parameters you can use. For more, see`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git log --help
`}</code></pre>
    <h2>{`Replace local changes`}</h2>
    <p>{`In case you did something wrong, you can replace local changes using the command`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git checkout -- <filename>
`}</code></pre>
    <p>{`This replaces the changes in your working tree with the last content in HEAD. Changes already added to the index, as well as new files, will be kept.`}</p>
    <p>{`If you instead want to drop all your local changes and commits, fetch the latest history from the server and point your local master branch at it like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git fetch origin
git reset --hard origin/master
`}</code></pre>
    <h2>{`Useful commands`}</h2>
    <p>{`Here are few more useful git commands:`}</p>
    <p>{`Built-in git GUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`gitk
`}</code></pre>
    <p>{`Use colorful git output`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git config color.ui true
`}</code></pre>
    <p>{`Show log on just one line per commit`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git config format.pretty oneline
`}</code></pre>
    <p>{`Use interactive adding`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`git add -i
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      